<template>
    <div id="achievements_slider">
        <div v-if="spWatch && !tabletWatch && !pcWatch">
            <Splide 
                :options="splideOptionsLeftSp"
                :extensions="extensions"
                aria-label="お気に入りの写真"
            >
                <SplideSlide v-for="image in images" :key="image.id">
                    <img :src="require(`@/assets/achievements_slider/${image.imgpath}`)" :alt="image.name" />
                </SplideSlide>
            </Splide>
            <Splide 
                :options="splideOptionsRightSp"
                :extensions="extensions"
                aria-label="お気に入りの写真"
            >
                <SplideSlide v-for="image in center_images" :key="image.id">
                    <img :src="require(`@/assets/achievements_slider/${image.imgpath}`)" :alt="image.name" />
                </SplideSlide>
            </Splide>
            <Splide 
                :options="splideOptionsLeftSp"
                :extensions="extensions"
                aria-label="お気に入りの写真"
            >
                <SplideSlide v-for="image in end_images" :key="image.id">
                    <img :src="require(`@/assets/achievements_slider/${image.imgpath}`)" :alt="image.name" />
                </SplideSlide>
            </Splide>
        </div>

        <div v-if="tabletWatch && !spWatch && !pcWatch">
            <Splide 
                :options="splideOptionsLeftTablet"
                :extensions="extensions"
                aria-label="お気に入りの写真"
            >
                <SplideSlide v-for="image in images" :key="image.id">
                    <img :src="require(`@/assets/achievements_slider/${image.imgpath}`)" :alt="image.name" />
                </SplideSlide>
            </Splide>
            <Splide 
                :options="splideOptionsRightTablet"
                :extensions="extensions"
                aria-label="お気に入りの写真"
            >
                <SplideSlide v-for="image in end_images" :key="image.id">
                    <img :src="require(`@/assets/achievements_slider/${image.imgpath}`)" :alt="image.name" />
                </SplideSlide>
            </Splide>
        </div>

        <div v-if="pcWatch & !spWatch & !tabletWatch">
            <Splide 
                :options="splideOptionsLeft"
                :extensions="extensions"
                aria-label="お気に入りの写真"
            >
                <SplideSlide v-for="image in images" :key="image.id">
                    <img :src="require(`@/assets/achievements_slider/${image.imgpath}`)" :alt="image.name" />
                </SplideSlide>
            </Splide>
            <Splide 
                :options="splideOptionsRight"
                :extensions="extensions"
                aria-label="お気に入りの写真"
            >
                <SplideSlide v-for="image in end_images" :key="image.id">
                    <img :src="require(`@/assets/achievements_slider/${image.imgpath}`)" :alt="image.name" />
                </SplideSlide>
            </Splide>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
    import '@splidejs/vue-splide/css';
    import '@splidejs/vue-splide/css/skyblue';
    import '@splidejs/vue-splide/css/sea-green';
    import '@splidejs/vue-splide/css/core';

    export default {
        name: "InfiniteSlider",
        components: {
            Splide,
            SplideSlide,
        },
        props: {
            images: Array,
            center_images: Array,
            end_images: Array,
        },
        data() {
            return {
                spWatch: true,
                tabletWatch: false,
                pcWatch: false,
                splideOptions: {
                    arrows: false,
                    pagination: false,
                    drag: false,
                    swipe: false,
                    rewind: true,
                    type: "loop",
                    perPage: 10,
                    gap: '30px',
                    direction: 'ltr',
                    autoScroll: {
                        speed: 0.5,
                        pauseOnHover: false,
                    },
                },
                splideOptionsRight: {
                    arrows: false,
                    pagination: false,
                    drag: false,
                    swipe: false,
                    rewind: true,
                    type: "loop",
                    perPage: 6,
                    gap: '10px',
                    direction: 'ltr',
                    autoScroll: {
                        speed: -0.2,
                        pauseOnHover: false,
                    },
                },
                splideOptionsLeft: {
                    arrows: false,
                    pagination: false,
                    drag: false,
                    swipe: false,
                    rewind: true,
                    type: "loop",
                    perPage: 6,
                    gap: '10px',
                    direction: 'ltr',
                    autoScroll: {
                        speed: 0.2,
                        pauseOnHover: false,
                    },
                },
                splideOptionsRightTablet: {
                    arrows: false,
                    pagination: false,
                    drag: false,
                    swipe: false,
                    rewind: true,
                    type: "loop",
                    perPage: 5,
                    gap: '15px',
                    direction: 'ltr',
                    autoScroll: {
                        speed: -0.2,
                        pauseOnHover: false,
                    },
                },
                splideOptionsLeftTablet: {
                    arrows: false,
                    pagination: false,
                    drag: false,
                    swipe: false,
                    rewind: true,
                    type: "loop",
                    perPage: 5,
                    gap: '15px',
                    direction: 'ltr',
                    autoScroll: {
                        speed: 0.2,
                        pauseOnHover: false,
                    },
                },
                splideOptionsRightSp: {
                    arrows: false,
                    pagination: false,
                    drag: false,
                    swipe: false,
                    rewind: true,
                    type: "loop",
                    perPage: 3,
                    gap: '10px',
                    direction: 'ltr',
                    autoScroll: {
                        speed: -0.1,
                        pauseOnHover: false,
                    },
                },
                splideOptionsLeftSp: {
                    arrows: false,
                    pagination: false,
                    drag: false,
                    swipe: false,
                    rewind: true,
                    type: "loop",
                    perPage: 3,
                    gap: '10px',
                    direction: 'ltr',
                    autoScroll: {
                        speed: 0.1,
                        pauseOnHover: false,
                    },
                },
                extensions: { AutoScroll },
            };
        },
        mounted() {
            this.displaySize();
        },
        methods: {
            displaySize() {
                var screenWidth = ref(window.innerWidth); // 初期値として画面幅を設定
                screenWidth.value = window.innerWidth;
                if (screenWidth.value <= 767) {
                    this.spWatch = true;
                    this.pcWatch = false;
                    this.tabletWatch = false;
                } else if (768 <= screenWidth.value && screenWidth.value <= 1100) {
                    this.spWatch = false;
                    this.pcWatch = false;
                    this.tabletWatch = true;
                } else if (1101 <= screenWidth.value) {
                    this.spWatch = false;
                    this.tabletWatch = false;
                    this.pcWatch = true;
                }
                console.log(screenWidth.value);
                console.log(this.spWatch, this.tabletWatch, this.pcWatch);
            },
        }
    };
</script>

<style scoped>
    .splide {
        padding: 10px 0;
    }
</style>
