<template>
    <section>
        <div class="wrap">
            <div class="title">
                <h2>全国の大手企業、中心企業、店舗様から<br class="sp-only" />選ばれています</h2>
            </div>
        </div>
        <!-- Splide document：https://ja.splidejs.com/documents/ -->
        <UiAchievementsSlider class="slider" :images="images" :center_images="center_images" :end_images="end_images" />
        <div class="wrap">
            <div class="sub-title">
                <h3>
                    国内最大級の支援実績
                </h3>
            </div>
        </div>
    </section>
</template>

<script>
    import UiAchievementsSlider from '../Ui/AchievementsSlider.vue';

    export default {
        name: "AchievementsSlider",
        components: {
            UiAchievementsSlider,
        },
        data() {
            return {
                images: [
                    { id: 1, imgpath: "slider_001.webp", name: "slider_001" },
                    { id: 2, imgpath: "slider_002.webp", name: "slider_002" },
                    { id: 3, imgpath: "slider_003.webp", name: "slider_003" },
                    { id: 4, imgpath: "slider_004.webp", name: "slider_004" },
                    { id: 5, imgpath: "slider_005.webp", name: "slider_005" },
                    { id: 6, imgpath: "slider_006.webp", name: "slider_006" },
                    { id: 7, imgpath: "slider_007.webp", name: "slider_007" },
                    { id: 8, imgpath: "slider_008.webp", name: "slider_008" },
                    { id: 9, imgpath: "slider_009.webp", name: "slider_009" },
                    // { id: 10, imgpath: "slider_010.webp", name: "slider_010" },
                    { id: 11, imgpath: "slider_011.webp", name: "slider_011" },
                    { id: 12, imgpath: "slider_012.webp", name: "slider_012" },
                    { id: 13, imgpath: "slider_013.webp", name: "slider_013" },
                    { id: 14, imgpath: "slider_014.webp", name: "slider_014" },
                    { id: 15, imgpath: "slider_015.webp", name: "slider_015" },
                    { id: 16, imgpath: "slider_016.webp", name: "slider_016" },
                    { id: 17, imgpath: "slider_017.webp", name: "slider_017" },
                    { id: 18, imgpath: "slider_018.webp", name: "slider_018" },
                    { id: 19, imgpath: "slider_019.webp", name: "slider_019" },
                    { id: 20, imgpath: "slider_020.webp", name: "slider_020" },
                    { id: 21, imgpath: "slider_021.webp", name: "slider_011" },
                    { id: 22, imgpath: "slider_022.webp", name: "slider_012" },
                ],
                center_images: [ // 011~022, 001~010
                    { id: 11, imgpath: "slider_011.webp", name: "slider_011" },
                    { id: 12, imgpath: "slider_012.webp", name: "slider_012" },
                    { id: 13, imgpath: "slider_013.webp", name: "slider_013" },
                    { id: 14, imgpath: "slider_014.webp", name: "slider_014" },
                    { id: 15, imgpath: "slider_015.webp", name: "slider_015" },
                    { id: 16, imgpath: "slider_016.webp", name: "slider_016" },
                    { id: 17, imgpath: "slider_017.webp", name: "slider_017" },
                    { id: 18, imgpath: "slider_018.webp", name: "slider_018" },
                    { id: 19, imgpath: "slider_019.webp", name: "slider_019" },
                    { id: 20, imgpath: "slider_020.webp", name: "slider_020" },
                    { id: 21, imgpath: "slider_021.webp", name: "slider_011" },
                    { id: 22, imgpath: "slider_022.webp", name: "slider_012" },
                    { id: 1, imgpath: "slider_001.webp", name: "slider_001" },
                    { id: 2, imgpath: "slider_002.webp", name: "slider_002" },
                    { id: 3, imgpath: "slider_003.webp", name: "slider_003" },
                    { id: 4, imgpath: "slider_004.webp", name: "slider_004" },
                    { id: 5, imgpath: "slider_005.webp", name: "slider_005" },
                    { id: 6, imgpath: "slider_006.webp", name: "slider_006" },
                    { id: 7, imgpath: "slider_007.webp", name: "slider_007" },
                    { id: 8, imgpath: "slider_008.webp", name: "slider_008" },
                    { id: 9, imgpath: "slider_009.webp", name: "slider_009" },
                    // { id: 10, imgpath: "slider_010.webp", name: "slider_010" },
                ],
                end_images: [  // 逆順
                    { id: 22, imgpath: "slider_022.webp", name: "slider_012" },
                    { id: 21, imgpath: "slider_021.webp", name: "slider_011" },
                    { id: 20, imgpath: "slider_020.webp", name: "slider_020" },
                    { id: 19, imgpath: "slider_019.webp", name: "slider_019" },
                    { id: 18, imgpath: "slider_018.webp", name: "slider_018" },
                    { id: 17, imgpath: "slider_017.webp", name: "slider_017" },
                    { id: 16, imgpath: "slider_016.webp", name: "slider_016" },
                    { id: 15, imgpath: "slider_015.webp", name: "slider_015" },
                    { id: 14, imgpath: "slider_014.webp", name: "slider_014" },
                    { id: 13, imgpath: "slider_013.webp", name: "slider_013" },
                    { id: 12, imgpath: "slider_012.webp", name: "slider_012" },
                    { id: 11, imgpath: "slider_011.webp", name: "slider_011" },
                    // { id: 10, imgpath: "slider_010.webp", name: "slider_010" },
                    { id: 9, imgpath: "slider_009.webp", name: "slider_009" },
                    { id: 8, imgpath: "slider_008.webp", name: "slider_008" },
                    { id: 7, imgpath: "slider_007.webp", name: "slider_007" },
                    { id: 6, imgpath: "slider_006.webp", name: "slider_006" },
                    { id: 5, imgpath: "slider_005.webp", name: "slider_005" },
                    { id: 4, imgpath: "slider_004.webp", name: "slider_004" },
                    { id: 3, imgpath: "slider_003.webp", name: "slider_003" },
                    { id: 2, imgpath: "slider_002.webp", name: "slider_002" },
                    { id: 1, imgpath: "slider_001.webp", name: "slider_001" },
                ],
            }
        }
    }
</script>

<style lang="scss" scoped>
    section {
        background: linear-gradient(45deg, #FFD700, #d6a514, #FFF8DC);
        background-size: 400% 400%;
        animation: gradientAnimation 5s ease infinite;
        padding: 120px 0;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
            padding: 50px 0;
        }
        @media screen and (max-width: 767px) {
            padding: 30px 0;
        }
        .wrap {
            @media screen and (max-width: 767px) {
                padding: 0;
            }
            .title, .sub-title {
                width: 100%;
                background-color: #FFFFFF00;
                h2, h3 {
                    font-size: 35px;
                    font-weight: 500;
                    text-align: center;
                    color: #fff;
                    margin: 0;
                    @media screen and (min-width: 768px) and (max-width: 1100px) {
                        font-size: 28px;
                        font-weight: 500;
                    }
                    @media screen and (max-width: 767px) {
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 1.7rem;
                    }
                    @media screen and (max-width: 375px) {
                        font-size: 16px;
                        font-weight: 500;
                    }
                    .sp-only {
                        display: none;
                        @media screen and (max-width: 767px) {
                            display: block;
                        }
                    }
                }
            }
        }
    }
    @keyframes gradientAnimation {
        0% { background-position: 0% 50%; }
        50% { background-position: 100% 50%; }
        100% { background-position: 0% 50%; }
    }
</style>