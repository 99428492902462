<template>
  <section id="voice">
    <div class="wrap">
      <div class="voice">
        <div class="title">
          <h2>Voice</h2>
          <h3>お客様の声</h3>
        </div>
        <div class="content">
          <!-- <div class="voice-card">
            <img class="clip" style="left: 40px;" src="@/assets/clip.webp">
            <h4 class="card-title">株式会社 日本通信社<span class="keishou">様</span></h4>
            <p class="card-text">
              最初はお電話で、オフィス、店舗の電力料金削減のご案内をいただきました。
              <br>当社は事業拡大中で、電力料金などの固定費も毎月増加していたので、一度お話は聞いてみようということで、営業担当の方とお打ち合わせの日程を調整させていただきました。
              <br>当日は資料を用いて丁寧に説明をしてくださり、毎月の電力料、通話料、通信費用を10%以上も削減することができました！
              <br>他社様からも同サービスの提案はありましたが、決め手はこれまでの販売実績とアフターフォローの充実度でした。
              <br>今後も、引き続きよろしくお願いします。
            </p>
            <img
              class="card-img nihontsushin"
              src="@/assets/voice-nihontsushin.webp" alt="株式会社日本通信社 様"
            >
          </div>
          <div class="voice-card">
            <img class="clip" style="right: 40px;" src="@/assets/clip.webp">
            <h4 class="card-title">九州通信<span class="keishou">様</span></h4>
            <p class="card-text">
              オフィスビルの業務用エアコンが経年劣化していましたので、業務用エアコンを切り替えようということで、日本通信基地局様に問い合わせをさせていただきました。翌日の午後には担当者様が弊社に来社され、対応のスピードに驚かされました。
              <br>ご提案も業務用エアコンだけでなくLEDライトも一緒に切り替えることで、電気代が70%削減も削減できるという提案をいただき、非常に感謝しています。
              <br>今後も最新のサービスがありましたら、ご提案をよろしくお願いします。
            </p>
            <img
              class="card-img kyushutsushin"
              src="@/assets/voice-kyushutsushin.webp" alt="九州通信 様"
            >
          </div>
          <div class="voice-card">
            <img class="clip" style="left: 35%;" src="@/assets/clip.webp">
            <h4 class="card-title">有限会社 人形の沖牟田節句人形専門店<span class="keishou">様</span></h4>
            <p class="card-text">
              電力サービスのお申し込みを従量、動力プランの両方で契約いたしました。以前に他社企業様からも、電気料金削減の提案をいただいていたのですが、営業担当さんが熱心な方だったので、応援の意味も込めて申し込みをさせていただきました。
              <br>電気料金も以前より、毎月10%は安く利用ができています。
              <br>今後もより良いサービスのご提案をお待ちしています。
            </p>
            <img
              class="card-img ningyosenmonten"
              src="@/assets/voice-ningyosenmonten.webp" alt="九州通信 様"
            >
          </div> -->


          <!-- <div class="voice-card">
            <img class="clip" style="left: 40px;" src="@/assets/clip.webp">
            <h4 class="card-title"><span class="long">ヤマエグループホールディングス株式会社</span><span class="keishou long">様</span></h4>
            <p class="card-text">
              高圧電力サービスの切り替えをサポートしていただきました。他社とも複数社の相見積もりをさせていただきましたが、1番料金がお安く、何より安定性の高い料金プランでしたので、ご契約させていただきました。アフターフォローも充実しているので、大満足しています。
            </p>
            <img
              class="card-img nihontsushin"
              src="@/assets/voice-yamaegroup-hd.webp" alt="ヤマエグループホールディングス株式会社 様"
            >
          </div> -->
          
          <div class="voice-card">
            <img class="clip" style="right: 20px;" src="@/assets/clip.webp">
            <h4 class="card-title">むらおか<span class="keishou">様</span></h4>
            <p class="card-text">
              低圧電力サービスの切り替えと食用油の削減契約をさせていただきました。とても信頼ができる営業担当さんでよかったです。
            </p>
            <img
              class="card-img muraoka"
              src="@/assets/achievements_slider/slider_022.webp" alt="むらおか 様"
            >
          </div>
          <div class="voice-card">
            <img class="clip" style="left: 35%;" src="@/assets/clip.webp">
            <h4 class="card-title">Ito MOMO Nepal Cafe<span class="keishou">様</span></h4>
            <p class="card-text">
              以前、他社の営業提案で失敗をした経験があったのですが、日本通信基地局さんに契約を任せて本当によかったです。これからも経営サポートを宜しくお願いします。
            </p>
            <img
              class="card-img nihontsushin"
              src="@/assets/achievements_slider/slider_002.webp" alt="Ito MOMO Nepal Cafe 様"
            >
          </div>
          <div class="voice-card">
            <img class="clip" style="right: 40px;" src="@/assets/clip.webp">
            <h4 class="card-title">ラーメン みゆき家<span class="keishou">様</span></h4>
            <p class="card-text">
              豊富な契約実績と営業担当の方の印象がよかったので、お申込みをさせていただきました。年末の忙しい時に、スピーディーに対応をしていただいたのも非常に好感がもてました。大満足です。
            </p>
            <img
              class="card-img kyushutsushin"
              src="@/assets/achievements_slider/slider_014.webp" alt="ラーメン みゆき家 様"
            >
          </div>
          <div class="voice-card">
            <img class="clip" style="left: 35%;" src="@/assets/clip.webp">
            <h4 class="card-title"><span class="long">有限会社 人形の沖牟田節句人形専門店</span><span class="keishou long">様</span></h4>
            <p class="card-text">
              電力サービスのお申し込みを従量、動力プランの両方で契約いたしました。以前に他社企業様からも、電気料金削減の提案をいただいていたのですが、営業担当さんが熱心な方だったので、応援の意味も込めて申し込みをさせていただきました。
              <br>電気料金も以前より、毎月10%は安く利用ができています。
              <br>今後もより良いサービスのご提案をお待ちしています。
            </p>
            <img
              class="card-img ningyosenmonten"
              src="@/assets/voice-ningyosenmonten.webp" alt="有限会社 人形の沖牟田節句人形専門店 様"
            >
          </div>
        </div>
      </div>
    </div>

    <div class="side-cover">
      <!-- 白背景 -->
    </div>
  </section>
</template>

<script>
export default {
  name: "VoiceVue",
}
</script>

<style lang="scss" scoped>
  section {
    position: relative;
    height: calc(240px + 1200px);
    padding: 120px 0;
    background-color: var(--sub3);
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      height: calc(160px + 1600px);
    }
    @media screen and (max-width: 767px) {
      padding: 50px 0;
      height: auto;
    }
    .wrap {
      position: absolute;
      top: 120px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        top: 120px;
      }
      @media screen and (max-width: 767px) {
        position: static;
        top: 0;
        left: 0;
        transform: translateX(0);
      }
      .voice {
        display: flex;
        flex-wrap: wrap;
        height: 1200px;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          height: 1050px;
        }
        @media screen and (max-width: 767px) {
          display: block;
          height: auto;
        }
        .title {
          width: 35%;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
          h2 {
            font-size: 70px;
            font-weight: 600;
            line-height: 70px;
            text-align: start;
            color: var(--sub1);
            margin-bottom: 10px;
            @media screen and (min-width: 768px) and (max-width: 1100px) {
              font-size: 55px;
              line-height: 55px;
            }
            @media screen and (max-width: 767px) {
              font-size: 37px;
              line-height: 37px;
              text-align: center;
            }
          }
          h3 {
            font-size: 18px;
            font-weight: 500;
            color: var(--sub1);
            @media screen and (max-width: 767px) {
              text-align: center;
              margin-bottom: 20px;
            }
          }
        }
        .content {
          width: 65%;
          @media screen and (max-width: 767px) {
            width: 100%;
            padding: 0;
          }
          .voice-card {
            position: relative;
            padding: 30px;
            margin-bottom: 80px;
            background-color: var(--gray);
            @media screen and (max-width: 767px) {
              padding: 30px 15px;
              margin-top: 40px;
              margin-bottom: 60px;
            }
            .clip {
              position: absolute;
              top: -65px; 
              width: auto;
              height: 80px;
              @media screen and (max-width: 767px) {
                top: -45px; 
                height: 60px;
              }
            }
            .card-title {
              font-size: 23px;
              font-weight: 500;
              line-height: 25px;
              padding: 20px;
              margin-bottom: 20px;
              color: var(--sub1);
              background-color: var(--white);
              @media screen and (max-width: 767px) {
                font-size: 21px;
                line-height: 27px;
              }
              .long {
                font-size: 18px;
                @media screen and (max-width: 767px) {
                  font-size: 16px;
                }
              }
              .keishou {
                margin-left: 0.5em;
                @media screen and (max-width: 767px) {
                  margin-left: 0.5em;
                }
              }
            }
            .card-img {
              position: absolute;
              width: auto;
              height: 160px;
              @media screen and (min-width: 768px) and (max-width: 1100px) {
                height: 130px;
              }
              @media screen and (max-width: 767px) {
                position: static;
                width: 100%;
                height: auto;
                margin-top: 10px;
              }
            }
            .nihontsushin {
              top: -65px;
              right: -40px;
              @media screen and (min-width: 768px) and (max-width: 1100px) {
                top: auto;
                bottom: -25px;
                left: -180px;
              }
              @media screen and (max-width: 767px) {
                top: 0;
                right: auto;
                bottom: auto;
                left: 0;
              }
            }
            .muraoka {
              top: -45%;
              left: 230px;
              @media screen and (min-width: 768px) and (max-width: 1100px) {
                top: auto;
                left: -180px;
                bottom: -25px;
              }
              @media screen and (max-width: 767px) {
                top: 0;
                right: auto;
                bottom: auto;
                left: 0;
              }
            }
            .kyushutsushin {
              bottom: -25px;
              left: -220px;
              @media screen and (min-width: 768px) and (max-width: 1100px) {
                left: -180px;
              }
              @media screen and (max-width: 767px) {
                top: 0;
                right: auto;
                bottom: auto;
                left: 0;
              }
            }
            .ningyosenmonten {
              bottom: -80px;
              right: -40px;
              @media screen and (min-width: 768px) and (max-width: 1100px) {
                bottom: -25px;
                left: -180px;
              }
              @media screen and (max-width: 767px) {
                top: 0;
                right: auto;
                bottom: auto;
                left: 0;
              }
            }
          }
        }
      }
    }
    .side-cover {
      position: absolute;
      top: 0;
      right: 0;
      width: 1600px;
      height: calc(240px + 1200px);
      padding: 120px;
      z-index: 1;
      background-color: var(--white);
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        top: 0;
        height: calc(160px + 1600px);
      }
      @media screen and (max-width: 767px) {
        display: none;
      } 
    }
  }
</style>
